exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-zundfolge-article-js": () => import("./../../../src/templates/zundfolge-article.js" /* webpackChunkName: "component---src-templates-zundfolge-article-js" */),
  "component---src-templates-zundfolge-js": () => import("./../../../src/templates/zundfolge.js" /* webpackChunkName: "component---src-templates-zundfolge-js" */)
}

